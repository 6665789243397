<template>
  <div v-if="! showOld" class="login">
    <div class="left">
      <el-image :src="JPUTLogIn" :style="{width: '100%', height: '100%', overflow: 'hidden'}"/>
    </div>
    <div class="right">
      <div class="center">
        <div>
          <div class="logo">
            <el-image :src="JPUTLogoHorizontalBlack" class="text-center" />
          </div>
          <div style="padding-top: 20px;" v-loading="loading">
            <div class="login-tabs">
              <div :class="itemClass1" @click="() => loginTab = 1">Customer and JPUT Staff Login</div>
              <div :class="itemClass2"  @click="() => { loginTab = 2; showQR = false }">Barge and Operator Login (MVP)</div>
            </div>
            <div style="padding-top: 20px;">
              <loginCustomerAndJPUTStaff v-if="showCustomerAndJPUtStaffLogin" @load="(val) => loading = val"></loginCustomerAndJPUTStaff>
              <loginBargeAndOperator v-if="showBargeAndOperatorLogin" @load="(val) => loading = val" @qr="showQR = true"></loginBargeAndOperator>
              <loginQRCode v-if="showLoginQRCode" @passwordLogin="showQR = false"></loginQRCode>
            </div>
          </div>
        </div>
      </div>
      <div class="announcementBanner">
        <Announcement></Announcement>
      </div>

    </div>
  </div>
  <div v-else>
    <loginOld></loginOld>
  </div>
</template>
<script>
import { removeToken, removeMvpToken } from "@/utils/auth";
import JPUTLogIn from "@/assets/JPUTLogIn.jpg";
import JPUTLogoHorizontalBlack from "@/assets/JPUTLogoHorizontalBlack.png";
import loginCustomerAndJPUTStaff from "./loginCustomerAndJPUTStaff.vue";
import loginBargeAndOperator from "./loginBargeAndOperator.vue";
import loginQRCode from "./loginQRCode.vue";
import loginOld from "./loginOld.vue";
import Announcement from "./announcement.vue";

export default {
  name: 'Login',
  components: {
    loginCustomerAndJPUTStaff,
    loginBargeAndOperator,
    loginQRCode,
    loginOld,
    Announcement,
  },
  data() {
    return {
      loading: false,
      showOld: false,
      JPUTLogIn,
      JPUTLogoHorizontalBlack,
      loginTab: 1,
      showQR: false,
      isMvp: false,
    };
  },
  created() {
    removeToken();
    removeMvpToken();
    sessionStorage.removeItem("pageStatus");
    sessionStorage.removeItem("editData");
    this.isMvp = ( this.$route?.query?.isMvp ?? 'false' ) == 'true' ? true : false
    this.loginTab = this.isMvp ? 2 : 1
  },
  computed: {
    itemClass1() {
      return this.getItemClass(1)
    },
    itemClass2() {
      return this.getItemClass(2)
    },
    showCustomerAndJPUtStaffLogin() {
      return this.loginTab == 1
    },
    showBargeAndOperatorLogin() {
      return this.loginTab == 2 && ! this.showQR
    },
    showLoginQRCode() {
      return this.loginTab == 2 && this.showQR
    },
  },
  methods: {
    getItemClass( index ) {
      return this.loginTab == index ? 'item active' : 'item'
    },
  }
};
</script>
<style scoped lang="scss">
.login {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: row;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  justify-content: center;
  align-items: center;
  background-color: white;
  overflow: hidden;
  font-size: 11px;
  font-weight: 400;
  color: #82889C;

  .center {
    margin: calc((100vh - 510px)/2) auto 0 auto;
    width: 400px;
    height: 510px;
  }
  .left {
    width: 35%;
    height: 100%;
    overflow: hidden;

    img {
      width: 100% !important;
      height: 100% !important;
    }
  }
  .right {
    width: 65%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .logo {
    width: 120px; 
    margin: 0px auto;
  }
  .login-tabs {
    display: flex; 
    width: 100%;
    text-align: center;

    .item {
      flex: 50%;
      border-bottom: 2px solid #DADBE1;
      padding: 10px 0px;
      cursor: pointer;
    }
    .item.active{
      color: #000000;
      border-bottom: 2px solid #0FC000;
      font-weight: 500;
    }
  }
}
@media only screen and (max-width: 600px) {
  .login {
    overflow-x: hidden;
    overflow-y: auto;

    .center {
      margin: calc((100vh - 530px)/2) auto;
      width: 100%;
      height: 510px;
    }
    .left {
      display: none;
    }
    .right {
      width: 100% !important;
      padding: 10px;
    }
    .announcementBanner{
      margin-top: -50px;
    }
  }
}
</style>
