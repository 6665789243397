<template>
  <div class="login" :style="{ backgroundImage: 'url(' + loginBg + ')' }">
    <!-- <img class="jput-logo" :src="jputImg" /> -->
    <div class="login-main">
      <el-form label-position="auto" ref="form" :model="form" size="small" :inline-message="true">
        <el-form-item prop="username" :label="$t('ifm.username')" :rules="[
          {
            required: true,
            message: $t('tips.sr'),
            trigger: ['change', 'blur'],
          },
        ]">
          <el-input class="w-full" clearable size="small" v-model="form.username" @keyup.enter.native="doLogin" />
        </el-form-item>
        <el-form-item :label="$t('ifm.psw')" prop="password" :rules="[
          {
            required: true,
            message: $t('tips.sr'),
            trigger: ['change', 'blur'],
          },
        ]">
          <el-input class="w-full" clearable type="password" size="small" show-password v-model="form.password"
            @keyup.enter.native="doLogin" />
        </el-form-item>
        <el-form-item prop="checkTa" :rules="[
          {
            required: true,
            message: 'You are required to agree to Terms of Use and Service Agreement before you can login',
            trigger: ['change', 'blur'],
          },
        ]">
          <div class="flex flex-row justify-center items-center gap-2">
            <el-checkbox v-model="form.checkTa"></el-checkbox>
            <p style="font-size: 12px; line-height: 13px;">Please make sure you have read and agree to the <a href="Terms of Use.pdf" target="_blank" style="text-decoration: underline;">Terms of Use</a> and <a href="SaaS Agreement.pdf" target="_blank" style="text-decoration: underline;">Service Agreement</a></p>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button size="medium" type="primary" :loading="btnLoading" @click="doLogin" style="width: 250px">{{ $t("btn.signIn")
          }}</el-button>
        </el-form-item>
        <el-form-item>
          <div style="width: 100%; text-align: center">
            <el-link type="info" :underline="true" @click="forget">Forgot Password?</el-link>
          </div>
        </el-form-item>
        <hr  style="border-color: gray; opacity: 30%;">
        <div class="flex flex-row items-center justify-center cursor-pointer gap-x-2 pt-6" @click="$router.push('/mvpLogin')">
          <el-link type="info" :underline="true">Login With MVP</el-link>
          <!-- <img class="avatar" :src="iconImg" /> -->
        </div>
      </el-form>

      <div>
        <!-- <el-button
          style="margin-top: 20px; color: #049eff; border-color: #049eff"
          @click="$router.push('/activate')"
          >{{ $t("btn.aa") }}
        </el-button> -->
      </div>
    </div>
  </div>
</template>
<script>
import { removeToken, removeMvpToken } from "@/utils/auth";
import loginIcon from "@/assets/loginIcon.png";
import loginBg from "@/assets/loginbg.png";
import { forgetPwd, checkCode } from "@/services/user";
import { setToken, setCheckTs, getCheckTs } from "../utils/auth";
import iconImg from "@/assets/icon.png";
import jputImg from "@/assets/jurong-port-logo.png";

export default {
  data() {
    return {
      form: {
        username: "",
        password: "",
        checkTa: null
      },
      value1: "",
      btnLoading: false,
      loginIcon,
      loginBg,
      iconImg,
      jputImg
    };
  },
  created() {
    removeToken();
    removeMvpToken();
    sessionStorage.removeItem("pageStatus");
    sessionStorage.removeItem("editData");
  },
  watch: {
    'form.username': function (newVal) {
      const checkTs = getCheckTs(newVal);
      if (checkTs == 'true') {
        this.form.checkTa = true;
      } else {
        this.form.checkTa = null;
      }
    },
    'form.checkTa': function (newVal) {
      if (newVal === false) {
        this.form.checkTa = null;
      }
    }
  },
  methods: {
    doLogin() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const user = {
            username: this.form.username,
            password: this.form.password,
          };
          this.btnLoading = true;
          this.$store
            .dispatch("goLogin", user)
            .then((res) => {
              if (res.token && res.twoFactorEnabled == "false") {
                this.$message.success(this.$t("status.success"));
                setToken(res.token);
                this.$store.dispatch("getUserInfo").then((url) => {
                  this.$router.push(url);
                });
                setCheckTs(this.form.username);
              } else {
                this.$prompt(this.$t("ifm.pevc"), {
                  confirmButtonText: this.$t("btn.comfirm"),
                  cancelButtonText: this.$t("btn.cancel"),
                  inputPattern: /\S/,
                  inputErrorMessage: this.$t("tips.sr"),
                  beforeClose: (action, instance, done) => {
                    if (action == "confirm") {
                      checkCode(instance.inputValue).then((resA) => {
                        if (resA.code == 200) {
                          this.$message.success(this.$t("status.success"));
                          done();
                          setToken(resA.token);
                          this.$store.dispatch("getUserInfo").then((url) => {
                            this.$router.push(url);
                          });
                          setCheckTs(this.form.username);
                        } else {
                          this.$message.error(this.$t("ifm.error"));
                        }
                      });
                    } else {
                      done();
                    }
                  },
                });
              }
            })
            .finally(() => (this.btnLoading = false));
        } else {
          return false;
        }
      });
    },
    forget() {
      this.$prompt("Please input your email", {
        confirmButtonText: this.$t("btn.comfirm"),
        cancelButtonText: this.$t("btn.cancel"),
        inputPattern: /@/,
        inputErrorMessage: "Email format error",
        beforeClose: (action, instance, done) => {
          if (action == "confirm") {
            forgetPwd({ email: instance.inputValue }).then((res) => {
              if (res.code == 200) {
                this.$message.success(res.msg ?? this.$t("status.success"));
                done();
                this.router.push("/");
              } else {
                this.$message.ElMessage.error(this.$t("ifm.error"));
              }
            });
          } else {
            done();
          }
        },
      });
    },
    goVerify() { },
  },
};
</script>
<style scoped lang="scss">
.login {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  justify-content: center;
  align-items: center;

  .logo {
    width: 50%;
    height: 100%;
    padding: 50px;
    box-sizing: border-box;

    .img {
      width: 300px;
      font-size: 40px;
      color: #fff;
      text-align: center;
      line-height: 70px;
      margin-top: 200px;
      margin-left: 130px;
    }

    .title {
      font-weight: bolder;
      font-size: 50px;
      text-align: left;
    }
  }
}

::v-deep .el-form-item {
  // display: unset;
}

::v-deep .el-form-item__label {
  color: $grayColor;
  font-size: 14px;
}

.login-main {
  max-width: 270px;
  
  .el-button {
    width: 100%;
    margin: 0;
  }

  .el-button {
    // border-radius: 20px;
    position: relative;

    img {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.jput-logo {
  width: 150px;
}

.avatar {
  width: 120px;
  height: 120px;
}
</style>
