var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login",style:({ backgroundImage: 'url(' + _vm.loginBg + ')' })},[_c('div',{staticClass:"login-main"},[_c('el-form',{ref:"form",attrs:{"label-position":"auto","model":_vm.form,"size":"small","inline-message":true}},[_c('el-form-item',{attrs:{"prop":"username","label":_vm.$t('ifm.username'),"rules":[
        {
          required: true,
          message: _vm.$t('tips.sr'),
          trigger: ['change', 'blur'],
        },
      ]}},[_c('el-input',{staticClass:"w-full",attrs:{"clearable":"","size":"small"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.doLogin.apply(null, arguments)}},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('ifm.psw'),"prop":"password","rules":[
        {
          required: true,
          message: _vm.$t('tips.sr'),
          trigger: ['change', 'blur'],
        },
      ]}},[_c('el-input',{staticClass:"w-full",attrs:{"clearable":"","type":"password","size":"small","show-password":""},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.doLogin.apply(null, arguments)}},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1),_c('el-form-item',{attrs:{"prop":"checkTa","rules":[
        {
          required: true,
          message: 'You are required to agree to Terms of Use and Service Agreement before you can login',
          trigger: ['change', 'blur'],
        },
      ]}},[_c('div',{staticClass:"flex flex-row justify-center items-center gap-2"},[_c('el-checkbox',{model:{value:(_vm.form.checkTa),callback:function ($$v) {_vm.$set(_vm.form, "checkTa", $$v)},expression:"form.checkTa"}}),_c('p',{staticStyle:{"font-size":"12px","line-height":"13px"}},[_vm._v("Please make sure you have read and agree to the "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"Terms of Use.pdf","target":"_blank"}},[_vm._v("Terms of Use")]),_vm._v(" and "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"SaaS Agreement.pdf","target":"_blank"}},[_vm._v("Service Agreement")])])],1)]),_c('el-form-item',[_c('el-button',{staticStyle:{"width":"250px"},attrs:{"size":"medium","type":"primary","loading":_vm.btnLoading},on:{"click":_vm.doLogin}},[_vm._v(_vm._s(_vm.$t("btn.signIn")))])],1),_c('el-form-item',[_c('div',{staticStyle:{"width":"100%","text-align":"center"}},[_c('el-link',{attrs:{"type":"info","underline":true},on:{"click":_vm.forget}},[_vm._v("Forgot Password?")])],1)]),_c('hr',{staticStyle:{"border-color":"gray","opacity":"30%"}}),_c('div',{staticClass:"flex flex-row items-center justify-center cursor-pointer gap-x-2 pt-6",on:{"click":function($event){return _vm.$router.push('/mvpLogin')}}},[_c('el-link',{attrs:{"type":"info","underline":true}},[_vm._v("Login With MVP")])],1)],1),_c('div')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }