<template>
    <div v-if="showAnnouncement" class="banner">
        <div class="flex flex-row">
            <div class="basis-1/4 pr-2 flex items-center">
                <svg-icon v-if="svg" :icon-class="svg" class="announcementIcon"></svg-icon>
            </div>
            <div class="basis-3/4">
                <p class="announcementTitle">Announcement</p>
                <p>{{ this.message }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { getMaintenanceDetails } from "@/services/form"
import SvgIcon from "@/components/SvgIcon.vue";

export default {
    name: 'Announcement',
    components: { SvgIcon },
    data() {
        return {
            showAnnouncement:false,
            svg:"announcement",
            message:"",
        }
    },
    async created() {
        await this.getAnnouncement()
    },
    methods: {
        async getAnnouncement(){
            await getMaintenanceDetails( this.params ).then(res => {
                if(res.data){
                    this.showAnnouncement = true;
                    this.message = res.data.value;
                }
            }).finally(() => {
                // this.loading = false
            })
        },
    },
};
</script>
<style scoped lang="scss">
.banner{
    background-color: #eafbff; 
    height: max-content;
    padding: 10px;
    // border-radius: 10px;
}
.announcementIcon{
    height: 30px;
    width: 30px;
}
.announcementTitle{
    color: #000;
}
</style>