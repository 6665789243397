<template>
  <div class="text-center" style="font-size: 11px">
    <div class="small-text">Scan With Marine Vessel Pass app to log in</div>
    <div class="frameContainer">
      <el-image :src="qrContainer" class="qrContainerImage"></el-image>
      <iframe class="frame" :src="qrUrl"></iframe>
    </div>
    <div class="bottom">
      <div>
        <el-button class="qrBtn" type="success" plain @click="passwordLogin" size="small">Back to Password Login</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import qrContainer from "@/assets/qrContainer.png";

export default {
  name: 'LoginQRCode',
  data() {
    return {
      qrContainer,
      qrUrl: '',
    }
  },
  created() {
    const redirect = encodeURIComponent(process.env.VUE_APP_FE_BASE_API + '/loginByCode')
    this.qrUrl = `${process.env.VUE_APP_AUTH_BASE_API}/token/qrConnect?client_id=jput&redirect_uri=${redirect}&state=STATE`
  },
  methods: {
    passwordLogin() {
      this.$emit('passwordLogin')
    }
  }
}
</script>
<style scoped lang="scss">
.small-text {
  text-align: center;
  color: black;
  font-size: 9px;
  font-weight: 700;
}
.frameContainer {
  width: 280px;
  height: 280px;
  padding: 10px 30px;
  border-radius: 10px;
  text-align: center;
  margin: 10px auto;
  position: relative;
}
.qrContainerImage {
  width: 280px;
  height: 280px;
  position: absolute;
  top: 0;
  left: 0;
}
.frame {
  width: 220px; 
  height:220px; 
  border: medium none; 
  margin-bottom: 15px;
  position: fixed;
}
.bottom {
  text-align: center;
  padding: 10px 0px;
}
</style>